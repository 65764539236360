import React from 'react'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Crousal = () => {

    const GoWhatsapp = () => {
        window.open('https://wa.me/917867868200?text=Sir%2C%20I%20Need%20Id%20With%20Best25%20Offer', '_blank');
    }

    return (
        <div className='Crousal' onClick={GoWhatsapp} >



            <Carousel autoPlay={true} showStatus={false} showIndicators={false} swipeable infiniteLoop showArrows={false} interval="5000" showThumbs={false} className='cp' >
                <div  >
                    <img alt="" src="FG1.jpeg" />
                </div>

                <div>
                    <img alt="" src="FG2.jpeg" />
                </div>

                <div>
                    <img alt="" src="FG3.jpeg" />
                </div>

            </Carousel>
        </div>
    )
}

export default Crousal