import Home from "./Home";
import { Routes, Route, useLocation } from 'react-router-dom';
import Login from "./Login";
import MenuCricket from "./Subpage/MenuCricket";
import MenuFootball from "./Subpage/MenuFootball";



function App() {

  const location = useLocation();

  return (
    <div className="App">

      <Routes location={location} key={location.key}>
        <Route path="/*" element={<Home />} />
        <Route path="Home" element={<Home />} />
        <Route path="Login" element={<Login />} />
        <Route path="Cricket" element={<MenuCricket/>} />
        <Route path="Football" element={<MenuFootball/>} />
        
        
        
      </Routes>

    </div>
  );
}

export default App;
