import React from 'react'
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import { Link } from 'react-router-dom';

const CatBox = () => {
    return (
        <div>
            <div className='CatBoxRow'>
                <span style={{ color: '#1E8067' }} ><EnergySavingsLeafIcon /></span>
                <div className="pdL1" >Indian Card Games</div>
            </div>


            <Link to='/Login' className='CatBoxItemBox' >
                <div className='CatBoxItem' >
                    <img src="/indian/1.jpg" alt="" />
                </div>

                <div className='CatBoxItem' >
                    <img src="/indian/2.jpg" alt="" />
                </div>

                <div className='CatBoxItem' >
                    <img src="/indian/3.png" alt="" />
                </div>

                <div className='CatBoxItem' >
                    <img src="/indian/4.png" alt="" />
                </div>
                <div className='CatBoxItem' >
                    <img src="/indian/5.png" alt="" />
                </div>

                <div className='CatBoxItem' >
                    <img src="/indian/6.jpg" alt="" />
                </div>

                <div className='CatBoxItem' >
                    <img src="/indian/7.jpg" alt="" />
                </div>

                <div className='CatBoxItem' >
                    <img src="/indian/8.jpg" alt="" />
                </div>

                <div className='CatBoxItem' >
                    <img src="/indian/9.jpg" alt="" />
                </div>

                <div className='CatBoxItem' >
                    <img src="/indian/10.jpg" alt="" />
                </div>

                <div className='CatBoxItem' >
                    <img src="/indian/11.png" alt="" />
                </div>

                <div className='CatBoxItem' >
                    <img src="/indian/12.jpg" alt="" />
                </div>

                <div className='CatBoxItem' >
                    <img src="/indian/13.png" alt="" />
                </div>

                <div className='CatBoxItem' >
                    <img src="/indian/14.png" alt="" />
                </div>

                <div className='CatBoxItem' >
                    <img src="/indian/15.png" alt="" />
                </div>

                <div className='CatBoxItem' >
                    <img src="/indian/16.png" alt="" />
                </div>

                <div className='CatBoxItem' >
                    <img src="/indian/17.png" alt="" />
                </div>

                <div className='CatBoxItem' >
                    <img src="/indian/18.jpg" alt="" />
                </div>

                <div className='CatBoxItem' >
                    <img src="/indian/19.png" alt="" />
                </div>
                <div className='CatBoxItem' >
                    <img src="/indian/20.jpg" alt="" />
                </div>
                <div className='CatBoxItem' >
                    <img src="/indian/21.jpg" alt="" />
                </div>
                <div className='CatBoxItem' >
                    <img src="/indian/22.jpg" alt="" />
                </div>
                <div className='CatBoxItem' >
                    <img src="/indian/23.jpg" alt="" />
                </div>
                <div className='CatBoxItem' >
                    <img src="/indian/24.jpg" alt="" />
                </div>
                <div className='CatBoxItem' >
                    <img src="/indian/25.jpg" alt="" />
                </div>
                <div className='CatBoxItem' >
                    <img src="/indian/26.jpg" alt="" />
                </div>
                <div className='CatBoxItem' >
                    <img src="/indian/27.jpg" alt="" />
                </div>
                <div className='CatBoxItem' >
                    <img src="/indian/28.jpg" alt="" />
                </div>
                <div className='CatBoxItem' >
                    <img src="/indian/29.jpg" alt="" />
                </div>
                <div className='CatBoxItem' >
                    <img src="/indian/30.jpg" alt="" />
                </div>
                <div className='CatBoxItem' >
                    <img src="/indian/31.jpg" alt="" />
                </div>
                <div className='CatBoxItem' >
                    <img src="/indian/32.jpg" alt="" />
                </div>
                <div className='CatBoxItem' >
                    <img src="/indian/33.jpg" alt="" />
                </div>
                <div className='CatBoxItem' >
                    <img src="/indian/34.jpg" alt="" />
                </div>
                <div className='CatBoxItem' >
                    <img src="/indian/35.jpg" alt="" />
                </div>
            </Link>

        </div>
    )
}

export default CatBox