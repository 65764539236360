import React from 'react'
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import { Link } from 'react-router-dom';

const Popular = () => {
    return (
        <div>
            <div className='CatBoxRow'>
                <span style={{ color: '#1E8067' }} ><EnergySavingsLeafIcon /></span>
                <div className="pdL1" >Popular Card Games</div>
            </div>

            <Link to='/Login' className='PopularBoxItemBox'  >


                <div className='PopularBoxItem' >
                    <img src="/Popular/1.png" alt="" />
                </div>

                <div className='PopularBoxItem' >
                    <img src="/Popular/2.png" alt="" />
                </div>

                <div className='PopularBoxItem' >
                    <img src="/Popular/3.png" alt="" />
                </div>

                <div className='PopularBoxItem' >
                    <img src="/Popular/4.png" alt="" />
                </div>
                <div className='PopularBoxItem' >
                    <img src="/Popular/5.png" alt="" />
                </div>

                <div className='PopularBoxItem' >
                    <img src="/Popular/6.png" alt="" />
                </div>

                <div className='PopularBoxItem' >
                    <img src="/Popular/7.png" alt="" />
                </div>

                <div className='PopularBoxItem' >
                    <img src="/Popular/8.png" alt="" />
                </div>

                <div className='PopularBoxItem' >
                    <img src="/Popular/9.png" alt="" />
                </div>


                <div className='PopularBoxItem' >
                    <img src="/Popular/10.png" alt="" />
                </div>

                <div className='PopularBoxItem' >
                    <img src="/Popular/11.png" alt="" />
                </div>
                <div className='PopularBoxItem' >
                    <img src="/Popular/12.png" alt="" />
                </div>





            </Link>

        </div>

    )
}

export default Popular