import React from 'react'
import SportsCricketIcon from '@mui/icons-material/SportsCricket';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import SportsTennisIcon from '@mui/icons-material/SportsTennis';
import BedroomBabyIcon from '@mui/icons-material/BedroomBaby';
import { Link } from 'react-router-dom';

const MiniCat = () => {
    return (
        <div className='MiniCatBox' >
            <Link to='/Cricket' className='MiniCatCard Game1BG' ><div> <div><SportsCricketIcon /></div> Cricket</div></Link>
            <Link to='/Football' className='MiniCatCard Game2BG'><div> <div><SportsSoccerIcon /></div> Football</div></Link>
            <Link to='/Login' className='MiniCatCard Game3BG'><div> <div><SportsTennisIcon /></div> Tennis</div></Link>
            <Link to='/Login' className='MiniCatCard Game4BG'><div> <div><BedroomBabyIcon /></div> Horse</div></Link>
        </div>
    )
}

export default MiniCat