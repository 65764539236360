import React from 'react'
import Nav from '../Nav'

const Menu_Football = () => {
  return (
    <div>
      <Nav />
      <div className='SubMenuPageHeadLine' >Football</div>
      <div style={{ padding: '10px' }} >Competitions</div>
      <div>
        <div className='SubMenuPage_items' >English League 1</div>
        <div className='SubMenuPage_items' >English National League</div>
        <div className='SubMenuPage_items' >Greek Cup</div>
        <div className='SubMenuPage_items' >Regionalliga Northeast</div>
        <div className='SubMenuPage_items' >Mexican Liga MX</div>
        <div className='SubMenuPage_items' >Premiership</div>
        <div className='SubMenuPage_items' >FA Cup</div>
        <div className='SubMenuPage_items' >J.League 2</div>
        <div className='SubMenuPage_items' >Super League</div>
        <div className='SubMenuPage_items' >Liga Portugal</div>
        <div className='SubMenuPage_items' >Liga Portugal 2</div>
        <div className='SubMenuPage_items' >Primera Division</div>
        <div className='SubMenuPage_items' >LigaPro Primera A</div>
        <div className='SubMenuPage_items' >Argentinian Primera Division</div>
        <div className='SubMenuPage_items' >Segunda Division</div>
        <div className='SubMenuPage_items' >Italian Serie A</div>
        <div className='SubMenuPage_items' >Spanish La Liga</div>
        <div className='SubMenuPage_items' >English Premier League</div>
        <div className='SubMenuPage_items' >Spanish Segunda Division</div>
        <div className='SubMenuPage_items' >English Championship</div>
        <div className='SubMenuPage_items' >UEFA - Champions League</div>
        <div className='SubMenuPage_items' >CONMEBOL Copa Sudamericana</div>
        <div className='SubMenuPage_items' >Australian A-League Men</div>
      </div>

    </div>
  )
}

export default Menu_Football