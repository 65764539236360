import $ from 'jquery';


const preloader = () => {


    $(function () {
        $(".Preloader").fadeOut();
    });
    $(document).on("click", ".preloaderCls", function () {
        $(".Preloader").css("display", "none");
    });
    return (
        <>
            <div className='Preloader' >
                <div className='PreloaderLogo' ><img src="/logoo.png" alt="" /></div>
            </div>
        </>
    )
}

export default preloader