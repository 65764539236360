import React from 'react'
import { Link } from 'react-router-dom'


const Cat = () => {
    return (
        <>
            <div className='CatBox' >
                <Link to='/Login' className='CatCard Game10BG' >Evolution Gaming</Link>
                <Link to='/Login' className='CatCard Game20BG' >Sports Book</Link>
            </div>

            <div className='CatBox' >
                <Link to='/Login' className='CatCard Game30BG' >Slot Games</Link>
                <Link to='/Login' className='CatCard Game40BG' > E Cricket</Link>
            </div>

            <div className='CatBox' >
                <Link to='/Login' className='CatCard Game50BG' >Live Casinos</Link>
                <Link to='/Login' className='CatCard Game60BG' > Card Games</Link>
            </div>

            <div className='CatBox' >
                <Link to='/Login' className='CatCard Game70BG' >Multiplayer Game</Link>
                <Link to='/Login' className='CatCard Game80BG' > Fishing Game </Link>
            </div>


        </>

    )
}

export default Cat