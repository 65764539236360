import React from 'react'
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import { Link } from 'react-router-dom';

const Casino = () => {
    return (
        <div>
            <div className='CatBoxRow'>
                <span style={{ color: '#1E8067' }} ><EnergySavingsLeafIcon /></span>
                <div className="pdL1" >Casino Card Games</div>
            </div>
            <Link to='/Login' className='PopularBoxItemBox' >
                <div className='PopularBoxItem' >
                    <img src="/Casino/1.png" alt="" />
                </div>

                <div className='PopularBoxItem' >
                    <img src="/Casino/2.png" alt="" />
                </div>

                <div className='PopularBoxItem' >
                    <img src="/Casino/3.png" alt="" />
                </div>

                <div className='PopularBoxItem' >
                    <img src="/Casino/4.png" alt="" />
                </div>
                <div className='PopularBoxItem' >
                    <img src="/Casino/5.png" alt="" />
                </div>

                <div className='PopularBoxItem' >
                    <img src="/Casino/6.png" alt="" />
                </div>

                <div className='PopularBoxItem' >
                    <img src="/Casino/7.png" alt="" />
                </div>

                <div className='PopularBoxItem' >
                    <img src="/Casino/8.png" alt="" />
                </div>

                <div className='PopularBoxItem' >
                    <img src="/Casino/9.png" alt="" />
                </div>

                <div className='PopularBoxItem' >
                    <img src="/Casino/10.png" alt="" />
                </div>
            </Link>

        </div>

    )
}

export default Casino