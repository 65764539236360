import React from 'react'
import Nav from '../Nav'

const Menu_Cricket = () => {
  return (
    <div>
      <Nav/>
      <div className='SubMenuPageHeadLine' >Cricket</div>
      <div style={{padding:'10px'}} >Competitions</div>
      <div>
        <div className='SubMenuPage_items' >Indian Premier League</div>
        <div className='SubMenuPage_items' >Premier League SRL</div>
        <div className='SubMenuPage_items' >T20 World Cup, Women, Warm-Up</div>
        <div className='SubMenuPage_items' >T10 European Cricket League</div>
        <div className='SubMenuPage_items' >Test Matches</div>
        <div className='SubMenuPage_items' >Pakistan Super League</div>
        <div className='SubMenuPage_items' >One Day Internationals</div>
        <div className='SubMenuPage_items' >Women's Premier League</div>
        <div className='SubMenuPage_items' >Plunket Shield</div>
        <div className='SubMenuPage_items' >CSA 4-Day Franchise Series</div>
        <div className='SubMenuPage_items' >T20 International SRL</div>
        <div className='SubMenuPage_items' >SA T20 League SRL</div>
        <div className='SubMenuPage_items' >ICC Cricket World Cup League Two</div>
        <div className='SubMenuPage_items' >Big Bash League SRL</div>
        <div className='SubMenuPage_items' >CSA 4-Day Franchise Series</div>
      </div>
    </div>
  )
}

export default Menu_Cricket