import React from 'react'

const About = () => {

    const d = new Date();

    const GoWhatsapp = () => {
        window.open('https://wa.me/917867868200?text=Sir%2C%20I%20Need%20Id%20With%20Best%20Offer', '_blank');
      }

      const GoInsta = () => {
        window.open('https://www.instagram.com/bettingadda9?igsh=OHZwZ2lwN29mcnl3/', '_blank');
      }

      const GoTele = () => {
        window.open('https://t.me/Bettingadda9', '_blank');
      }
    
    return (
        <div className='AboutSec' >
            <div style={{ display: 'flex' }} >
                <div className='AboutBtn' >Introduction</div>
                <div className='AboutBtn' >How to Register</div>
            </div>

            <div style={{ display: 'flex' }} >
                <div className='AboutBtn' >How to Deposite</div>
                <div className='AboutBtn' >how to use bonus</div>
            </div>

            <p>
                <span> Betting Adda </span>
                is a limited liability company incorporated under the laws of Curacao. Players are requested not to contact any untrusted sources for
                <span> Betting Adda </span>
                accounts as this is an online site and they can only register with our whatsapp and  deposit through the account details generated by the system or provided by our official support team.
            </p>


            <div className='AboutIcon'>
                <div onClick={GoWhatsapp} ><img src="w.svg" alt="" /></div>
                <div onClick={GoInsta}><img src="i.svg" alt="" /></div>
                <div onClick={GoTele}><img src="t.svg" alt="" /></div>
            </div>

            <div className='AboutBtn' onClick={GoWhatsapp} >Contact Us</div>

            <div className='AboutIconBm'>
                <div><img src="upi.svg" alt="" /></div>
                <div><img src="bank.svg" alt="" /></div>
            </div>

            <div className='AboutIconBm'>
                <div><img src="3.svg" alt="" /></div>
                <div><img src="4.svg" alt="" /></div>
            </div>

            <div className='AboutIconBm'>
                <div><img src="5.svg" alt="" /></div>
                <div><img src="6.svg" alt="" /></div>
            </div>

            <div className='Copyright'>  © 2008-{d.getFullYear()} BettingAdda9.com - Premium Service.</div>
        </div>
    )
}

export default About